import { useEffect, useState } from "react";
import axios from "axios";
import { Keypoint, Pose } from "@tensorflow-models/pose-detection/dist/types";
import { useInterval } from "react-use";
import { BLAZEPOSE_SCORE_THRESHOLD, USE_WASM } from "@utils/Erised";

export type ISampler = {
  PASessionUID: string;
  shouldPostFrame?: boolean;
  frameRate?: number; // seconds
};

type IFrameSample = {
  pose: Pose;
  timestamp: number;
};

export type IFrameResult = {
  moving: boolean;
  valid: boolean;
  finished: boolean;
};

export const useFrameSampler = ({
  PASessionUID,
  shouldPostFrame = false,
  ...props
}: ISampler): [
  boolean,
  IFrameResult,
  (pose: Pose) => Promise<void>,
  () => void
] => {
  const [isValidFrame, setIsValidFrame] = useState<boolean>(false);
  const [frameSample, setFrameSample] = useState<IFrameSample | null>(null);
  const [frameResult, setFrameResult] = useState(null);

  const frameRate = props?.frameRate || 10; // default is 1 FPS
  const [frameInterval, setFrameInterval] = useState(1000 / frameRate);

  useInterval(() => {
    flush();
  }, frameInterval);

  useEffect(() => {
    return stopStampling;
  }, []);

  const keypointsToFrame = (
    keypoints: Keypoint[],
    scoreThreshold: number = BLAZEPOSE_SCORE_THRESHOLD
  ) => {
    const result = {};

    for (const keypoint of keypoints) {
      if (!USE_WASM) {
        keypoint.z = 0.0;
      }

      if (keypoint.score >= scoreThreshold) {
        result[keypoint.name] = [
          keypoint.x,
          keypoint.y,
          keypoint.z,
          keypoint.score,
        ];
      }
    }

    return result;
  };

  const frameInPicture = (frame): boolean => {
    if (frame === null) {
      return false;
    }

    return (
      (frame.hasOwnProperty("right_hip") &&
        frame.hasOwnProperty("right_shoulder") &&
        frame.hasOwnProperty("right_knee")) ||
      (frame.hasOwnProperty("left_hip") &&
        frame.hasOwnProperty("left_shoulder") &&
        frame.hasOwnProperty("left_knee"))
    );
  };

  const setSample = async (pose: Pose) => {
    const timeOrigin =
      performance.timeOrigin || performance.timing.navigationStart;

    const timestamp = timeOrigin + performance.now();

    setFrameSample({ pose, timestamp });
  };

  const stopStampling = () => {
    setFrameInterval(null);
  };

  const flush = async () => {
    if (frameSample === null || PASessionUID === undefined) {
      return;
    }

    const { pose, timestamp } = frameSample;

    const frame = keypointsToFrame(pose.keypoints);

    const data = {
      PASessionUID,
      frame,
      timestamp,
    };

    if (frameInPicture(frame)) {
      setIsValidFrame(true);

      if (shouldPostFrame) {
        await axios
          .post("/api/poses/post_frame", data)
          .then((resp) => {
            setFrameResult(resp.data);
          })
          .catch((error) => {
            if (error.response) {
              // TODO: change this
              console.warn(error);
            } else {
              console.warn(error);
            }
          });
      }
    } else {
      setIsValidFrame(false);
    }
  };

  return [isValidFrame, frameResult, setSample, stopStampling];
};
