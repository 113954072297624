import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { CheckCircleIcon } from "@components/icons";
import lowerCase from "lodash/lowerCase";

import { Button } from "@components/button";
import { Alert } from "@components/alert";
import { Erised, LeadForm } from "@components";

import { useFrameSampler } from "./FrameSampler";
import { ISessionReport, useSessionController } from "./SessionController";

const poses = ["sit_and_reach", "straight_leg_raise", "knee_to_belly"];

const poseInstructions = {
  sit_and_reach: {
    name: "Sit & Reach",
    instructions: [
      "Sit on the floor",
      "Keep your legs straight",
      "Reach for your toes",
      "Hold for 3 seconds",
    ],
  },
  straight_leg_raise: {
    name: "Straight Leg Raise",
    instructions: [
      "Lie down on the floor",
      "Keep your legs straight",
      "Raise your leg (closest to the camera)",
      "Hold for 3 seconds",
    ],
  },
  knee_to_belly: {
    name: "Knee To Belly",
    instructions: [
      "Lie down on the floor",
      "Bring your knee as close as possible to your belly",
      "Hold for 3 seconds",
    ],
  },
};

type IPoseStep = {
  poseKey: "sit_and_reach" | "straight_leg_raise" | "knee_to_belly";
  skipPhase: () => void;
  initPhase: ({ poseKey: string }) => void;
};

type IBaseStep = {
  title: string;
};

type IIntroductionStep = IBaseStep & {
  nextStep: () => void;
  showButton?: boolean;
};

const BaseStep: React.FC<IBaseStep> = ({ title, children, ...props }) => {
  return (
    <div className="flex flex-col w-full min-h-full">
      <div className="pb-5 border-b border-gray-200">
        <h3 className="text-xl font-medium leading-6 text-gray-900">{title}</h3>
      </div>
      {children}
    </div>
  );
};

const IntroductionStep: React.FC<IIntroductionStep> = ({
  title,
  nextStep,
  showButton = true,
  children,
}) => {
  return (
    <BaseStep title={title}>
      <div className="flex-grow mt-4">{children}</div>
      <div className="flex w-full mt-4">
        {showButton && (
          <Button
            size="xl"
            appearance="primary"
            className="mx-auto"
            onClick={nextStep}
          >
            Next Step
          </Button>
        )}
      </div>
    </BaseStep>
  );
};

const PosePhaseStep: React.FC<IPoseStep> = ({
  poseKey,
  initPhase,
  skipPhase,
}) => {
  const pose = poseInstructions[poseKey];

  useEffect(() => {
    initPhase({ poseKey });
  }, [poseKey]);

  return (
    <BaseStep title={pose.name}>
      <div className="mt-4 rounded-lg shadow-md">
        <div className="object-contain pointer-events-none group-hover:opacity-75">
          <Image
            src={`/images/pa/${poseKey}.gif`}
            alt={poseKey}
            width={500}
            height={300}
            layout="responsive"
            className="rounded-lg"
          />
        </div>
      </div>
      <div className="mt-4">
        <ul className="text-xl divide-y divide-gray-200">
          {pose.instructions.map((instruction, idx) => (
            <li key={idx} className="py-2">
              {idx + 1}. {instruction}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex w-full mt-4">
        <Button
          size="md"
          appearance="discrete"
          className="mx-auto"
          onClick={skipPhase}
        >
          Skip pose
        </Button>
      </div>
    </BaseStep>
  );
};

type ISummaryStep = {
  sessionReport: ISessionReport;
  onLeadSuccess: (arg0: any) => void;
};

const SummaryStep: React.FC<ISummaryStep> = ({
  sessionReport,
  onLeadSuccess,
}) => {
  const [showReport, setShowReport] = useState<boolean>(false);

  const handleLeadSuccess = (data) => {
    setShowReport(true);

    onLeadSuccess(data);
  };

  const reportStatistics = (sessionReport: ISessionReport) => {
    const { phases } = sessionReport;

    console.log(phases);

    const stats = poses.map((key) => {
      const phaseData = phases.find((p) => p.pose_name === key);

      return {
        key: key,
        name: poseInstructions[key].name,
        stat: Math.round(phaseData.score * 100),
      };
    });

    return (
      <dl className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.key}
            className="px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6"
          >
            <dt className="text-sm font-medium text-gray-500 truncate">
              {item.name}
            </dt>
            <dd className="mt-1 text-3xl font-semibold text-primary-light-600">
              {item.stat}
              <span className="text-2xl text-gray-500">%</span>
            </dd>
          </div>
        ))}
      </dl>
    );
  };

  return (
    <BaseStep title={"Well done!"}>
      <div className="flex-grow mt-4">
        <p>
          You've completed your first ever automated flexibility assessment!
          We've run your poses through our algorithms and determined that your
          level is most likely:
        </p>
        <div className="my-4 rounded-lg shaddow-lg">
          <dl className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-2">
            <div
              key={"score"}
              className="px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6"
            >
              <dt className="text-sm font-medium text-gray-500 truncate">
                Flexibility Score
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-primary-light-600">
                {sessionReport.score}{" "}
                <span className="text-2xl text-gray-500">%</span>
              </dd>
            </div>
            <div
              key={"level"}
              className="px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6"
            >
              <dt className="text-sm font-medium text-gray-500 truncate">
                Level
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-primary-light-600">
                {sessionReport.level}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      {showReport ? (
        <div className="my-4 rounded-lg shaddow-lg">
          {reportStatistics(sessionReport)}
          <div className="flex mt-8">
            <Link href={`/app/videos/${lowerCase(sessionReport.level)}`}>
              <Button size="xl" appearance="primary" className="mx-auto">
                Watch Personalized Session
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="rounded-lg bg-green-50">
          <div className="px-4 py-12 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
              <span className="block">Want all the details?</span>
              <span className="block text-green-600">
                Get access to your personalized report.
              </span>
            </h2>
            <div className="flex mt-8 lg:mt-0 lg:flex-shrink-0">
              <LeadForm
                inputClassName="bg-white text-black focus:text-black"
                onSuccess={handleLeadSuccess}
              />
            </div>
          </div>
        </div>
      )}
    </BaseStep>
  );
};

export const PhysicalAssessment = (props) => {
  const [step, setStep] = useState(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [phaseOutcome, setPhaseOutcome] = useState<
    "validated" | "skipped" | null
  >(null);

  const showErised = () => currentIndex >= 2 && currentIndex <= 5;

  const {
    PASessionUID,
    sessionReport,
    startSession,
    startPhase,
    identifySession,
    endSession,
  } = useSessionController();
  const [isValidFrame, frameResult, setFrame, stopSampler] = useFrameSampler({
    PASessionUID,
    shouldPostFrame: showErised() && phaseOutcome === null,
  });

  const nextStep = () => {
    if (currentIndex + 1 < steps.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const initPhase = ({ poseKey }) => {
    console.debug(
      "[PA] Init new phase with previous previous outcome",
      phaseOutcome
    );

    const prevPhaseOutcome = phaseOutcome || undefined;
    startPhase({ poseKey, prevPhaseOutcome });

    setPhaseOutcome(null);
  };

  const skipPhase = () => {
    setPhaseOutcome("skipped");
  };

  const onLeadSuccess = ({ id }) => {
    identifySession(id);
  };

  const steps = [
    <IntroductionStep
      title={"Why a Flexibility Assessment?"}
      nextStep={nextStep}
    >
      <p>
        Flexibility is an important component of physical fitness and has many
        positive effects on the body. For instance, it improves mobility,
        posture, muscle coordination, reduces the risk of injuries and muscle
        soreness.
      </p>
      <h3 className="mt-4 font-bold leading-6 text-gray-900 text-md">
        Who we are
      </h3>
      <p>
        Behale's mission is to improve physical wellbeing. Our technologies
        enables us to create an immersive experience for the user where his
        needs and level are taken into account at every step of his journey. The
        time where you struggle to find motivation is over : we're taking care
        of that for you.
      </p>
      <h3 className="mt-4 font-bold leading-6 text-gray-900 text-md">
        Privacy Notice
      </h3>
      <p>
        We take your privacy very seriously. Behale only sees a skeleton of your
        body joints and does not capture any background or interiors of your
        workout area. No image is sent to our server, we do not know what you
        look like or where you are.
      </p>
    </IntroductionStep>,
    <IntroductionStep title={"What's coming up next?"} nextStep={nextStep}>
      <div className="flex-col h-full">
        <p className="flex-shrink w-full">
          We will ask you to execute 3 poses to analyze the flexibility of your
          hips:
        </p>
        <ul className="grid flex-grow grid-cols-1 gap-6 sm:grid-cols-3">
          {poses.map((key) => (
            <li key={key} className="relative py-4">
              <h4 className="block mb-2 font-medium text-gray-900 truncate pointer-events-none">
                {poseInstructions[key].name}
              </h4>
              <div className="block w-full overflow-hidden bg-gray-100 rounded-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
                <img
                  src={`/images/pa/${key}.gif`}
                  alt=""
                  className="object-contain pointer-events-none group-hover:opacity-75"
                />
              </div>
            </li>
          ))}
        </ul>
        <Alert
          title={"Careful"}
          description={
            "Remember, keep your movements slow.  You should not feel discomfort at any point of this assessment. If you do at any point, stop the assessment."
          }
        />
      </div>
    </IntroductionStep>,
    <IntroductionStep
      title={"Setting everything up"}
      nextStep={nextStep}
      showButton={false}
    >
      <div className="text-xl">
        <ul className="mt-4 space-y-3">
          <li
            key="allow_camera"
            className="px-6 py-4 overflow-hidden bg-white rounded-md shadow"
          >
            Allow access to your camera.
          </li>
          <li
            key="step_away"
            className="px-6 py-4 overflow-hidden bg-white rounded-md shadow"
          >
            ️Please step away from your device.
          </li>
          <li
            key="lie_down"
            className="px-6 py-4 overflow-hidden bg-white rounded-md shadow"
          >
            Sit on the floor and make sure that your whole body is visible to
            the camera.
          </li>
          <li
            key="voila"
            className="px-6 py-4 overflow-hidden bg-white rounded-md shadow"
          >
            <span className="italic">Voilà!</span>
          </li>
        </ul>

        <div className="mt-4">
          <span className="text-sm font-medium text-gray-600">
            If the assessment doesn't start automatically, click{" "}
            <a href="#" className="underline fill-gray" onClick={nextStep}>
              here
            </a>
            .
          </span>
        </div>
      </div>
    </IntroductionStep>,
    <PosePhaseStep
      poseKey={"sit_and_reach"}
      initPhase={initPhase}
      skipPhase={skipPhase}
    ></PosePhaseStep>,
    <PosePhaseStep
      poseKey={"straight_leg_raise"}
      initPhase={initPhase}
      skipPhase={skipPhase}
    ></PosePhaseStep>,
    <PosePhaseStep
      poseKey={"knee_to_belly"}
      initPhase={initPhase}
      skipPhase={skipPhase}
    ></PosePhaseStep>,
    <SummaryStep sessionReport={sessionReport} onLeadSuccess={onLeadSuccess} />,
  ];

  useEffect(() => {
    setStep(steps[6]);
  }, [sessionReport]);

  useEffect(() => {
    if (phaseOutcome === "validated") {
      setTimeout(() => nextStep(), 3000);
    } else if (phaseOutcome === "skipped") {
      nextStep();
    }
  }, [phaseOutcome]);

  useEffect(() => {
    if (frameResult !== null) {
      if (frameResult.finished) {
        setPhaseOutcome("validated");
      }
    }
  }, [frameResult]);

  useEffect(() => {
    if (currentIndex == 6) {
      endSession();
      stopSampler();
    } else if (currentIndex == 2) {
      startSession();
    }

    setStep(steps[currentIndex]);
  }, [currentIndex]);

  useEffect(() => {
    if (isValidFrame) {
      if (currentIndex == 2) {
        nextStep();
      }
    }
  }, [isValidFrame]);

  return (
    <div className="flex flex-col flex-grow w-full h-full p-4 mx-auto bg-white rounded-lg shadow-md lg:flex-row sm:p-4 lg:p-8">
      <div className={`h-full mr-5 ${showErised() ? "" : "hidden"}`}>
        {phaseOutcome === "validated" && (
          <CheckCircleIcon
            className="absolute inset-0 z-10 m-auto text-green-500 duration-100 ease-in fill-current animate-pulse"
            height={200}
            width={200}
          />
        )}

        <Erised
          width={640}
          height={480}
          status={showErised() ? "on" : "off"}
          setFrame={setFrame}
          frameResult={frameResult}
        />
      </div>
      <div className="w-full mt-8 lg:mt-0">{step}</div>
    </div>
  );
};
