import styled from "styled-components";

export const StyledCanvas = styled.canvas`
  --moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`;

export const StyledVideo = styled.video`
  --moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`;