import React, { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";

const appearanceStyles = {
  primary:
    "text-white bg-primary-light hover:bg-primary focus:ring-primary",
  secondary:
    "text-primary bg-primary-lighter hover:bg-primary-lighter focus:ring-primary-lighter",
  alternate: "text-white bg-secondary hover:bg-secondary focus:ring-secondary",
  discrete:
    "text-primary-light bg-transparent border-primary-light hover:bg-primary-light focus:ring-primary-light hover:text-white",
  black:
    "bg-gray-900 text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-light",
  disabled: "text-grey-300 bg-grey-300 border-grey-300",
};

export type IButtonAppearance = keyof typeof appearanceStyles;

export type IButton = React.ButtonHTMLAttributes<HTMLButtonElement> &
  React.LinkHTMLAttributes<HTMLButtonElement> & {
    size?: "xs" | "sm" | "md" | "lg" | "xl";
    appearance?: IButtonAppearance;
  };

const sizeStyles = {
  xs: "px-2.5 py-1.5 text-xs rounded",
  sm: "px-3 py-2 text-sm leading-4 rounded-md",
  md: "px-4 py-2 text-sm rounded-md",
  lg: "px-4 py-2 text-base rounded-md",
  xl: "px-6 py-3 text-base rounded-md",
};

export const Button: React.FC<IButton> = forwardRef(
  (
    { children, className, type, href, ...props }: IButton,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const sizeStyle = sizeStyles[props.size || "sm"];
    const typeStyle = appearanceStyles[props.appearance || "primary"];

    const finalClassName = classNames(
      sizeStyle,
      typeStyle,
      "inline-flex items-center border border-transparent cursor-pointer font-medium shadow-sm focus:outline-none",
      "disabled:opacity-50 disabled:pointer-events-none disabled:text-gray-500 disabled:bg-gray-500 disabled:border-gray-500",
      className
    );

    return (
      <button className={finalClassName} ref={ref} type={type} {...props}>
        <div className="w-full">{children}</div>
      </button>
    );
  }
);
