import axios from "axios";
import { detect } from "detect-browser";
import { createBreakpoint } from "react-use";

export const browser = detect();

export const BREAKPOINTS = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

const useBreakpoint = createBreakpoint(BREAKPOINTS);

export type IDimensions = {
  width: number;
  height: number;
};

export const IS_MOBILE = [
  "ios",
  "android",
  "samsung",
  "edge-ios",
  "fxios",
  "opera-mini",
].includes(browser.name);

export const buildSteyaSessionURL = (sessionUID) =>
  `${process.env.STEYA_API_URL}/pa/sessions/${sessionUID}`;

export const parseFirstName = (name: string) =>
  name.split(" ").slice(0, -1).join(" ");

export const swrFetcher = (url: string) =>
  axios.get(url).then((res) => res.data);

export const median = (values: number[]): number => {
  if (values.length === 0) throw new Error("No inputs");

  values.sort(function (a, b) {
    return a - b;
  });

  var half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];

  return (values[half - 1] + values[half]) / 2.0;
};
