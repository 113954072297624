import React, { useEffect, useRef, useState } from "react";

import useRafLoop from "react-use/lib/useRafLoop";

import { BehaleIcon } from "@components/icons";
import { IDimensions, IErised } from "@interfaces";

import { StyledCanvas } from "./styled";

// TODO: load below dynamically
import { useDetector } from "./detector";
import { clearCtx, drawCtx, drawPose } from "./drawing";
import { Webcam } from "./webcam";

export const Erised: React.FC<IErised> = ({
  width,
  height,
  setFrame,
  status = "on",
  frameResult,
}: IErised) => {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  const [detector, detectorLoading] = useDetector({ modelType: "full" });

  const [keypointColor, setKeypointColor] = useState<string>("white");
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
  const [dimensions, setDimensions] = useState<IDimensions>({
    width,
    height,
  });

  const renderPose = async (video: HTMLVideoElement) => {
    performance.mark("erised_detector_estimation_start");

    const poses = await detector.estimatePoses(video, {
      maxPoses: 1,
      flipHorizontal: false,
    });

    performance.mark("erised_detector_estimation_end");

    if (poses.length > 0) {
      if (poses.length > 1) {
        throw new Error("Too many poses identified.");
      }

      const pose = poses[0];

      drawPose(context, pose, keypointColor);
      setFrame(pose);
    }
  };

  const [stopLoop, startLoop, isActive] = useRafLoop((time) => {
    if (webcamRef.current !== null) {
      const video: HTMLVideoElement = webcamRef.current.getVideo();

      if (webcamRef.current.isReady) {
        drawCtx(context, video, width, height);

        if (detector !== null) {
          renderPose(video);
        }
      }
    }
  }, false);

  const start = () => {
    // start webcam and wait for data
    if (webcamRef.current !== null) {
      webcamRef.current.start();
    }

    // reset detector if it's loaded
    if (detector !== null) {
      detector.reset();
    }

    // if canvas exists, set the context
    if (canvasRef.current !== null) {
      setContext(canvasRef.current.getContext("2d"));
    }

    startLoop();

    console.debug("[Erised] Successfully started.");
  };

  const stop = () => {
    // clear context
    if (canvasRef.current !== null && context !== null) {
      clearCtx(context, width, height);
    }

    // stop webcam
    if (webcamRef.current !== null) {
      webcamRef.current.stop();
    }

    // reset detector if it's loaded
    if (detector !== null) {
      detector.reset();
    }

    stopLoop();

    console.debug("[Erised] Successfully stopped.");
  };

  useEffect(() => {
    if (frameResult !== null && frameResult !== undefined) {
      if (frameResult.valid && !frameResult.moving) {
        setKeypointColor("teal");
      } else {
        setKeypointColor("white");
      }

      if (frameResult.finished) {
        setKeypointColor("white");
      }
    }
  }, [frameResult]);

  useEffect(() => {
    console.debug("[Erised] Dimensions changed", dimensions);

    const { width: videoWidth, height: videoHeight } = dimensions;

    if (canvasRef.current !== null) {
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;
    }

    if (containerRef.current !== null) {
      containerRef.current.width = videoWidth;
      containerRef.current.height = videoHeight;
    }

    console.log(webcamRef.current.isReady);
  }, [dimensions]);

  useEffect(() => {
    setDimensions({ width, height });
  }, [width, height]);

  useEffect(() => {
    if (status == "on") {
      start();
    }

    return stop;
  }, [status]);

  useEffect(() => {
    console.log(webcamRef.current);
    console.log(webcamRef.current.isReady);
    console.log(detectorLoading);
  }, [detectorLoading]);

  return (
    <div
      className="relative h-full mx-auto overflow-hidden rounded-lg shadow-md bg-gray-50 w-min min-w-min"
      ref={containerRef}
    >
      {detectorLoading ? (
        <BehaleIcon
          className="absolute inset-0 z-10 m-auto fill-current text-primary animate-spin"
          height={200}
          width={200}
        />
      ) : (
        <div></div>
      )}
      <StyledCanvas
        className="transform md:transform-gpu"
        ref={canvasRef}
      ></StyledCanvas>
      <Webcam
        ref={webcamRef}
        width={width}
        height={height}
        setCanvasDimensions={setDimensions}
      />
    </div>
  );
};
