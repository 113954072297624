import { useForm } from "react-hook-form";
import React, { useState } from "react";
import axios, { AxiosResponse } from "axios";
import classNames from "classnames";

import { Button, IButtonAppearance } from "@components/button";
import useTranslation from "next-translate/useTranslation";

type ILeadForm = React.HTMLAttributes<HTMLFormElement> & {
  buttonText?: string;
  onSuccess?: Function;
  inputClassName?: string;
  buttonAppearance?: IButtonAppearance;
  errorOnDuplicate?: boolean;
};

export const LeadForm: React.FC<ILeadForm> = (props: ILeadForm) => {
  const { t } = useTranslation("common");

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState("initial");

  const stateStyle = {
    initial: "border",
    error: "ring-red-500 ring-2",
    success: "ring-primary-light ring-2",
  };

  const createLead = async (data): Promise<AxiosResponse<any>> => {
    return axios.post(`${process.env.NEXT_PUBLIC_STEYA_API_URL}/leads/`, data, {
      headers: { "Access-Control-Allow-Origin": "*" },
    });
  };

  const onSubmit = async (data) => {
    await createLead(data)
      .then((resp) => {
        const { data, status } = resp;

        if (status == 200 && props.errorOnDuplicate) {
          setState("error");
          setError("email", {
            type: "server",
            message: t("email_error_message_already_subscribed"),
          });
        } else {
          if (props.onSuccess) {
            props.onSuccess(data);
          }

          setState("success");
          global.analytics.track("Lead Created", {
            data,
          });
          global.analytics.identify(data.id);
        }
      })
      .catch((error) => {
        if (error.response) {
          setState("error");
          setError("email", {
            type: "server",
            message: error.response.data.detail,
          });
        }
      });
  };

  const formClassName = classNames(
    "sm:mx-auto md:mx-0 w-full",
    props.className
  );

  const inputClassName = classNames(
    "block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-md focus:outline-none focus:border-transparent focus:ring-2 focus:ring-transparent focus:ring-offset-2 focus:ring-offset-primary",
    stateStyle[state],
    props.inputClassName
  );

  return (
    <form className={formClassName} onSubmit={handleSubmit(onSubmit)}>
      <div className="sm:flex">
        <div className="flex-1 min-w-0">
          <div className="relative w-full h-full">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              {...register("email", { required: true })}
              type="email"
              name="email"
              id="email"
              className={inputClassName}
              placeholder={t("common:email_placeholder")}
              onInput={() => setState("initial")}
            />
            {state !== "initial" && (
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                {errors.email ? (
                  <p className="text-sm text-red-500">{errors.email.message}</p>
                ) : (
                  <p className="text-sm text-primary-light">{t("email_success")}</p>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="mt-3 sm:mt-0 sm:ml-3">
          <Button
            size="lg"
            type="submit"
            appearance={props.buttonAppearance || "primary"}
            className={"h-full w-full py-3 px-4 rounded-md shadow-md"}
          >
            {props.buttonText || t("email_cta")}
          </Button>
        </div>
      </div>
    </form>
  );
};
