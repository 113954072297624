import { useEffect, useState } from "react";

import "@tensorflow/tfjs-backend-webgl";
import * as tfjsWasm from "@tensorflow/tfjs-backend-wasm";

import "@mediapipe/pose";
import * as tf from "@tensorflow/tfjs-core";
import * as PoseDetection from "@tensorflow-models/pose-detection";

import { USE_WASM, setBackend, setTFMode } from "@utils/Erised";

// FIX DEPRECATION: https://developer.chrome.com/blog/enabling-shared-array-buffer/

export const useDetector = ({
  modelType,
  ...props
}): [PoseDetection.PoseDetector, boolean] => {
  const [detector, setDetector] = useState<PoseDetection.PoseDetector | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);

  const config = {
    runtime: USE_WASM ? "mediapipe" : "tfjs",
    solutionPath: USE_WASM
      ? // TODO: Stop using CDN
        "/dist/mediapipe/"
      : undefined,
    enableSmoothing: true,
    modelType,
  };

  const initBackend = async () => {
    await tf.ready();

    setTFMode();

    if (USE_WASM) {
      // TODO: stop using CDN
      tfjsWasm.setWasmPaths("/dist/tfjs-backend-wasm/");

      setBackend("wasm");
      console.debug("[useDetector] TFJS - WASM Backend initialized.");
    } else {
      config.runtime = "tfjs";

      setBackend("webgl");
      console.debug("[useDetector] TFJS - WebGL Backend initialized.");
    }
  };

  const createDetector = async () => {
    const startTime = performance.now();
    console.debug("[useDetector] Creating detector...");
    PoseDetection.createDetector(
      PoseDetection.SupportedModels.BlazePose,
      config
    )
      .then((result) => {
        const endTime = performance.now();
        setDetector(result);
        console.debug(
          `[useDetector] Detector created in ${
            endTime - startTime
          } milliseconds.`
        );
      })
      .catch((reason) => {
        console.warn(reason);
        console.warn("[useDetector] Failed to create detector.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    initBackend();
    createDetector();
  }, []);

  return [detector, loading];
};
