import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { IErisedWebcamConfig, IDimensions } from "@interfaces";

import { StyledVideo } from "./styled";

const targetFPS = 60;
const facingMode = "user";

export const Webcam = forwardRef(
  (
    { width, height, setCanvasDimensions, ...props }: IErisedWebcamConfig,
    ref
  ) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const [loading, setLoading] = useState<boolean>(true);
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [videoDimensions, setVideoDimensions] = useState({ width, height });

    useImperativeHandle(ref, () => ({
      start: startMediaStream,
      stop: stopMediaStream,
      setDimensions: setVideoDimensions,
      getDimensions: getVideoDimensions,
      isReady: !loading,
      getVideo: () => videoRef.current,
    }));

    const isVideoReady = (): boolean =>
      stream !== null &&
      videoRef.current !== null &&
      videoRef.current.readyState >= 2;

    const awaitLoadedData = async () => {
      if (!isVideoReady()) {
        await new Promise((resolve) => {
          videoRef.current.onloadeddata = () => {
            resolve(videoRef.current);
          };
        });

        setLoading(false);
      }
    };

    const updateTrackConfig = async (props: IDimensions) => {
      if (stream !== null) {
        const track = stream.getTracks()[0];

        const newConfig = buildConfig(props);

        console.debug(
          "[Webcam#applyDimensionsToTrack] Apply new config to track",
          track.label,
          newConfig
        );

        return track
          .applyConstraints(newConfig)
          .then(() => {
            console.debug(
              "[Webcam#applyDimensionsToTrack] Successfully applied new constraints",
              track.getSettings()
            );
            playStreamOnVideo();
          })
          .catch((reason) => console.error(reason));
      }
    };

    const buildConfig = ({ width, height }) => ({
      aspectRatio: 1.33333,
      frameRate: {
        min: 24,
        ideal: targetFPS,
        max: 80,
      },
      facingMode,
      width: {
        min: 360,
        ideal: width,
        max: 1280,
      },
      height: {
        min: 270,
        ideal: height,
        max: 720,
      },
      resizeMode: "crop-and-scale",
    });

    const stopMediaStream = () => {
      if (stream !== null && stream.active) {
        const track = stream.getVideoTracks()[0];
        track.stop();
        console.debug("[Webcam] Stopped media stream.");
      }
    };

    const startMediaStream = async () => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error(
          "Browser API navigator.mediaDevices.getUserMedia not available"
        );
      }

      const mediaConfig = {
        audio: false,
        video: props.videoConfig || buildConfig(videoDimensions),
      };

      console.debug("[Webcam] Getting user media stream...", mediaConfig);

      navigator.mediaDevices
        .getUserMedia(mediaConfig)
        .then((mediaStream) => {
          console.debug("[Webcam] Successfully got user media stream.");

          const track = mediaStream.getVideoTracks()[0];

          if (track.getCapabilities !== undefined) {
            console.debug(
              "[Webcam] Camera capabilities",
              track.getCapabilities()
            );
          }

          console.debug(
            "[Webcam] Camera settings",
            mediaStream.getVideoTracks()[0].getSettings()
          );
          setStream(mediaStream);
        })
        .catch((error) => {
          console.warn("[Webcam] Failed to get user media.", error);
        });
    };

    const updateVideoDimensions = () => {
      if (videoRef.current !== null) {
        const { videoWidth, videoHeight } = videoRef.current;

        videoRef.current.width = videoWidth;
        videoRef.current.height = videoHeight;

        console.debug(
          "[Webcam] Updating video dimensions...",
          videoWidth,
          videoHeight
        );
        setVideoDimensions({ width: videoWidth, height: videoHeight });
      }
    };

    const getVideoDimensions = () => {
      if (videoRef.current !== null) {
        const { videoWidth, videoHeight } = videoRef.current;

        return { width: videoWidth, height: videoHeight };
      }
      return null;
    };

    const playStreamOnVideo = async () => {
      const video = videoRef.current;

      video.srcObject = stream;

      await new Promise((resolve) => {
        video.onloadedmetadata = () => {
          resolve(video);
        };
      });

      video.play();
      updateVideoDimensions();
    };

    useEffect(() => {
      console.debug("[Webcam#useEffect] New video dimensions", videoDimensions);
      if (
        stream !== null &&
        videoDimensions.width > 0 &&
        videoDimensions.height > 0
      ) {
        if (
          videoDimensions.width !== width ||
          videoDimensions.height !== height
        ) {
          console.debug(
            "[Webcam] Different sizes",
            videoDimensions,
            getVideoDimensions()
          );
          updateTrackConfig(videoDimensions);
        }
      }
    }, [videoDimensions]);

    useEffect(() => {
      console.debug("[Webcam#useEffect] New props dimensions", width, height);

      if (
        stream !== null &&
        videoDimensions.width > 0 &&
        videoDimensions.height > 0
      ) {
        if (
          videoDimensions.width !== width ||
          videoDimensions.height !== height
        ) {
          updateTrackConfig({ width, height });
        }
      }
    }, [width, height]);

    useEffect(() => {
      if (stream !== null) {
        playStreamOnVideo();
        awaitLoadedData();
      }

      return stopMediaStream;
    }, [stream]);

    return (
      <StyledVideo
        className="w-auto h-auto hidden"
        ref={videoRef}
        playsInline
      ></StyledVideo>
    );
  }
);
