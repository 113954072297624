import * as tf from "@tensorflow/tfjs-core";
import * as posedetection from "@tensorflow-models/pose-detection";
import { browser } from "@utils";

export const BREAKPOINT_VIDEO_SIZE = {
  xs: { width: 360, height: 270 },
  sm: { width: 360, height: 270 },
  md: { width: 640, height: 480 },
  lg: { width: 768, height: 576 },
  xl: { width: 1024, height: 720 },
  "2xl": { width: 1280, height: 720 },
};

export const DEFAULT_LINE_WIDTH = 2;
export const DEFAULT_RADIUS = 4;

export const BLAZEPOSE_MODEL = posedetection.SupportedModels.BlazePose;

export const BLAZEPOSE_MODEL_TYPES = ["lite", "full", "heavy"];

export const BLAZEPOSE_SCORE_THRESHOLD = 0.75;

export const USE_WASM = !(browser.name === "safari" || browser.name === "ios");

export const DEFAULT_WEBGL_FLAGS = {
  WEBGL_VERSION: 2,
  WEBGL_CPU_FORWARD: true,
  WEBGL_PACK: true,
  WEBGL_FORCE_F16_TEXTURES: false,
  WEBGL_RENDER_FLOAT32_CAPABLE: true,
  WEBGL_FLUSH_THRESHOLD: -1,
  CHECK_COMPUTATION_FOR_ERRORS: false,
};

export const setTFMode = () => {
  if (
    process.env.NODE_ENV == "development" &&
    process.env.NEXT_PUBLIC_ENABLE_TF_DEBUG
  ) {
    console.debug("[TF] Debug mode enabled.");
    tf.enableDebugMode();
  } else if (process.env.NODE_ENV == "production") {
    console.log("[TF] Running in prod mode.");
    tf.enableProdMode();
  }
};

export const setBackend = async (backendName: "wasm" | "webgl") => {
  const ENGINE = tf.engine();
  if (!(backendName in ENGINE.registryFactory)) {
    throw new Error(`${backendName} backend is not registed.`);
  }

  if (backendName in ENGINE.registry) {
    const backendFactory = tf.findBackendFactory(backendName);
    tf.removeBackend(backendName);
    tf.registerBackend(backendName, backendFactory);
  }

  await tf.setBackend(backendName);
};
