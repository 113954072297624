import axios from "axios";
import { useEffect, useState } from "react";
import { useEffectOnce, useSessionStorage, useLocalStorage } from "react-use";
import { ISampler } from "./FrameSampler";

type ISession = {};

type PhaseArgs = {
  poseKey: string;
  prevPhaseOutcome?: "validated" | "skipped";
};

type PhaseReport = {
  pose_name: string;
  score: number;
  outcome: string;
};

export type ISessionReport = {
  level: string;
  score: number;
  phases: PhaseReport[];
};

export const useSessionController = (
  props?: ISession
): {
  PASessionUID: string;
  sessionReport: ISessionReport;
  startSession: () => Promise<void>;
  startPhase: (PhaseArgs) => Promise<void>;
  identifySession: (arg0: number) => Promise<boolean>;
  endSession: () => Promise<void>;
} => {
  const [sessionReport, setSessionReport] = useState<ISessionReport>({
    score: 0,
    level: "N/A",
    phases: [],
  });
  const [
    PASessionUID,
    setPASessionUID,
    removePASessionUID,
  ] = useLocalStorage<string>("PASessionUID", "");

  useEffect(() => {
    return removePASessionUID;
  }, []);

  const startSession = async () => {
    await axios
      .post("/api/poses/start_session")
      .then(({ data }) => {
        console.debug("[useSessionController] Session started.", data);

        setPASessionUID(data.sessionUID);
      })
      .catch((reason) => {
        console.error(reason);
      });
  };

  const startPhase = async ({ poseKey, ...props }) => {
    await axios
      .post("/api/poses/post_phase", {
        poseKey,
        PASessionUID,
        ...props,
      })
      .then(({ data }) => {
        console.debug("[useSessionController] New phase started.", data);
      })
      .catch((reason) => {
        console.error(reason);
      });
  };

  const endSession = async () => {
    if (PASessionUID === undefined || PASessionUID === "") {
      console.debug(
        "[useSessionController] Skipping session finish due to invalid sessionUID",
        PASessionUID
      );
      return;
    }

    await axios
      .post("/api/poses/end_session", { PASessionUID })
      .then((resp) => {
        console.debug("[useSessionController] Session finished.", resp.data);
        const { score, level, phases } = resp.data;

        setSessionReport({
          score: Math.floor(score * 100),
          level,
          phases,
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404 || error.response.status === 422) {
            removePASessionUID();
          }
        } else {
          console.error(error.response);
        }
      });
  };

  const identifySession = async (leadId: number) => {
    await axios
      .post("/api/poses/update_session", { PASessionUID, leadId })
      .then((resp) => {
        console.debug("[useSessionController] Session identified.", resp.data);

        removePASessionUID();

        return true;
      })
      .catch((error) => {
        if (!error.response) {
          console.error(error.response);
        }
        return false;
      });

    return false;
  };

  return {
    PASessionUID,
    sessionReport,
    startSession,
    startPhase,
    identifySession,
    endSession,
  };
};
